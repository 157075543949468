.custom-barcode svg {
  width: 3.3cm;
  height: 1cm;
  overflow: hidden;
}
.product-name {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  white-space: normal;
  overflow: hidden;
  -webkit-line-clamp: 2;
  text-align: center;
  width: 3.3cm;
  font-size: 9px !important;
  margin-top: -5px !important;
  padding-left: 2px;
}
