body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

textarea {
  border-color: rgba(0, 0, 0, 0.23);
  outline-color: #00ab55;
  border-radius: 4px;
  padding: 8.5px 14px;
  font-family: inherit;
}

.MuiFormLabel-asterisk {
  color: #d32f2f;
}

.preview-image {
  width: 100%;
  margin-bottom: -4px;
}

/* .preview-image-icon {
  display: none !important;
}

.preview-image:hover {
  opacity: 0.4;
}

.preview-image:hover + .preview-image-icon {
  display: inline-block !important;
}

.preview-image-icon:hover + .preview-image {
  opacity: 0.4;
}

.preview-image-icon:hover {
  display: inline-block !important;
}

.preview-image.disabled:hover {
  opacity: 1;
}

.preview-image.disabled:hover + .preview-image-icon.disabled {
  display: none !important;
} */

.button-select-file {
  background-repeat: no-repeat;
  width: 100%;
  min-height: 194px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  padding: 0;
  overflow: hidden;
  position: relative;
}

.Mui-disabled,
textarea:disabled {
  -webkit-text-fill-color: rgb(102 102 102 / 82%) !important;
}

.MuiTableCell-sizeSmall {
  font-size: 15.5px !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

@media screen and (max-width: 1440px) {
  .MuiTableCell-sizeSmall {
    width: auto !important;
  }
}

.MuiChip-root.MuiChip-filled.Mui-disabled {
  opacity: 1;
}

.MuiTabs-flexContainer {
  flex-wrap: wrap;
}

svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeSmall.MuiTableSortLabel-icon.MuiTableSortLabel-iconDirectionAsc {
  opacity: 0.3;
}

svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeSmall.MuiTableSortLabel-icon.MuiTableSortLabel-iconDirectionDesc {
  opacity: 0.3;
}

.Mui-active .MuiTableSortLabel-icon {
  opacity: 1 !important;
}

.print {
  font-size: 13px;
  line-height: 22px;
}

.print table {
  border-collapse: separate;
  border-spacing: 0px;
}

.print td {
  padding: 5px 0;
}

.print em {
  font-size: 12px;
}

.sale__tab {
  max-width: 600px;
}

.sale__tab>div {
  max-width: 600px;
  overflow: auto !important;
  margin-bottom: 5px !important;
}

.sale__tab>div::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.sale__tab>div::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 20px;
}

.sale__tab>div::-webkit-scrollbar-thumb {
  background-color: #dfdfdf;
  border-radius: 20px;
}

.sale__tab>div>div {
  max-width: 600px;
  display: block !important;
}

.ILaAqXVruB>div>input {
  padding-right: 36px;
}

.data-filter .filter-item {
  background-color: #def7e5;
  box-shadow: rgb(38, 57, 77) 5px 5px 7px -3px;
  border-radius: 4px;
  padding: 5px;
  font-size: 0.875rem;
  display: flex;
  align-items: center;
}

.data-filter .filter-item span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}

.btn-delete-filter {
  margin-left: 6px;
  cursor: pointer;
  font-size: 12px;
}

.cursor-pointer {
  cursor: pointer !important;
}

.Mui-completed>svg {
  color: #0088ff !important;
}

.Mui-active>svg {
  color: rgba(0, 0, 0, 0.38) !important;
}

.pdf-export {
  display: none;
}

@media print {
  .pdf-export {
    display: block;
  }
}